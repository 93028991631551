import { isClient } from "./run-on-client-only"
import { CurrentAppUrl } from "@hornet-web-react/core/types/session"

export default function getCurrentAppUrl(
  urlProtocol: string,
  fallbackUrl: string
): CurrentAppUrl {
  if (isClient) {
    const clientCurrentAppUrl = CurrentAppUrl.safeParse(
      `${urlProtocol}://${window.location.host}`
    )
    if (clientCurrentAppUrl.success) {
      return clientCurrentAppUrl.data
    }
  }

  return CurrentAppUrl.parse(fallbackUrl)
}
