import { useCallback } from "react"
import { LocaleRoute } from "@hornet-web-react/core/types/session"

export function useHornetRouter() {
  const parse = useCallback((url: string, localeRoute: LocaleRoute) => {
    // normalize deeplink
    const supportedLinks = [
      "hrnt://",
      "https://hornet.com/",
      "http://hornet.com/",
      "https://hornetapp.com/",
      "http://hornetapp.com/",
    ]

    supportedLinks.forEach((u) => {
      if (url.indexOf(u) == 0) {
        url = url.replace(u, "/")
      }
    })

    // skip any URLs that don't start with our own (any http/https at this point)
    if (url.match("http://") || url.match("https://")) {
      return
    }

    // special deeplinks list - strip these and retry
    const strippedUrl = [
      "/open_webview_otp/",
      "/open_webview/",
      "/open_hornet_com/",
      "/open_webscreen_otp/",
    ].reduce((newUrl, deeplink) => {
      if (url.match(deeplink)) {
        newUrl = url.replace(deeplink, "/")
        return newUrl
      }

      return newUrl
    }, "")

    // magic #1: let's parse with the stripped URL, pretend like it's one of our own
    // e.g. `/open_webview_otp/feed` -> `/feed`
    if (strippedUrl != "") {
      return parse(strippedUrl, localeRoute)
    }

    function prependLocaleRoute(url: string) {
      if (!localeRoute || url.startsWith(`${localeRoute}`)) {
        return url
      }

      return `${localeRoute}/${url}`
    }

    switch (true) {
      case !!url.match(/\/profiles\/[a-zA-Z0-9]+\/[0-9]+$/): // profiles with username + ID
      case !!url.match(/\/profiles\/[a-zA-Z0-9]+$/): // profiles with username only
      case !!url.match(/\/grids\/[a-z_]+$/): // grids
      case !!url.match(/\/grids\/tag\/[a-zA-Z0-9]+$/): // grids with hashtag
      case !!url.match(/\/grids\/explore\/[0-9]+\.[0-9]+\/[0-9]+\.[0-9]+$/): // grids with location
      case !!url.match(/\/grids\/members\/[a-zA-Z0-9]+$/): // grids with username
      case !!url.match(/\/inbox\/[a-z_]+$/): // inbox
      case !!url.match(/\/inbox$/): // inbox
      case !!url.match(/\/shop\/.*$/): // shop
      case !!url.match(/\/feed$/): // feed
      case !!url.match(/\/notifications/): // notifications
      case !!url.match(/\/adhoc_campaigns\/[a-zA-Z0-9]+$/): // adhoc campaigns
        return prependLocaleRoute(url)
      default:
        return null
    }
  }, [])

  return {
    parse,
  }
}
