import { useCallback, useEffect, useState } from "react"
import { getCookie, setCookie } from "typescript-cookie"
import { CookieName } from "@hornet-web-react/core/types"
import {
  Country,
  CurrentAppUrl,
  DeviceId,
  DeviceLocation,
  InAppVersion,
  SessionQueryParamsRecord,
} from "@hornet-web-react/core/types/session"
import {
  useSessionApi,
  useSessionCommunity,
  useSessionDevice,
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import {
  UNKNOWN_DEVICE_ID,
  UNKNOWN_USER_COUNTRY,
} from "@hornet-web-react/core/utils/constants"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { useRouter } from "next/router"
import GoogleAnalyticsService from "@hornet-web-react/core/services/GoogleAnalyticsService"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import equals from "ramda/es/equals"
import getUserFromCookie from "@hornet-web-react/core/utils/get-user-from-cookie"
import { getCorrectValue } from "@hornet-web-react/core/utils/zod-schema-parse"
import { useEffectOnce } from "usehooks-ts"
import { isIOS, isAndroid } from "@hornet-web-react/core/utils/is-ios"
import getCommunityTokenFromCookie from "@hornet-web-react/core/utils/get-community-token-from-cookie"
import { debug } from "@hornet-web-react/core/utils"
import { getSessionStorageData } from "@hornet-web-react/core/utils/session-storage"
import LocalStorageService, {
  StorageKey,
} from "@hornet-web-react/core/services/LocalStorageService"
import { useEvent } from "react-use"
import { isInstalledAsPwa } from "@hornet-web-react/core/utils/is-installed-as-pwa"
import { z } from "zod"
import { AppConfig } from "@hornet-web-react/core/services/AppConfig"
import EventEmitter from "eventemitter3"

export const UseAppStartEmitter = new EventEmitter()

export const useAppStart = (appConfig: AppConfig) => {
  // hook into router for pageview tracking
  const router = useRouter()

  const [isAppStartDone, setIsAppStartDone] = useState(false)

  const {
    environment: { setInAppVersion, setCurrentAppUrl, setSessionQueryParams },
    device: {
      setDeviceId,
      storeDeviceId,
      setCountry,
      setCanAccessAppStore,
      setDeviceLocation,
    },
    user: { setUser },
    community: { setToken },
  } = useSessionApi()

  const { inAppVersion, currentAppUrl, sessionQueryParams } =
    useSessionEnvironment()
  const { deviceId, country, canAccessAppStore, deviceLocation } =
    useSessionDevice()
  const user = useSessionUser()
  const { token } = useSessionCommunity()
  const storageSessionQueryParams = getSessionStorageData()

  const localStorageService = useCoreService<LocalStorageService>(
    CORE_TYPES.LocalStorageService
  )
  const googleAnalyticsService = useCoreService<GoogleAnalyticsService>(
    CORE_TYPES.GoogleAnalyticsService
  )

  // trigger event tracking (report session start)
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )

  // PWA: refresh the page under certain conditions
  //        * when the app is stale (timestamp > 1 hour)
  //        * when a "failed to fetch" error occurs (network error)
  //        * this is determined by a cookie value that expires after 1 hour
  // PWA in Android can be stale for a long time
  const shouldReloadPwa = useCallback(() => {
    const cookieIsPwaFresh = getCorrectValue(
      getCookie(CookieName.IsPwaFresh) || null,
      z.literal("y")
    )

    // still fresh, do nothing
    if (cookieIsPwaFresh) {
      return false
    }

    // if it doesn't exist, then create it and reload
    setCookie(
      CookieName.IsPwaFresh,
      "y",
      appConfig.simpleCookieConfig({
        expires: new Date(new Date().getTime() + 60 * 60 * 1000), // add 1 hour
      })
    )

    return true
  }, [appConfig])

  const onVisibilityChange = useCallback(() => {
    // if this is PWA, let's just refresh the page to make sure user
    // has clean slate and also latest version of the app
    if (
      isInstalledAsPwa() &&
      document.visibilityState === "visible" &&
      shouldReloadPwa()
    ) {
      // also, this is most likely clashing with push notification navigation
      // where the app is reloaded and the notification target URL is lost
      window.location.reload()
    }
  }, [shouldReloadPwa])

  useEvent("visibilitychange", onVisibilityChange)

  useEffectOnce(() => {
    debug(`useAppStart@useEffectOnce: setup`)
    // set state from cookies
    // there's checks for difference in values to prevent unnecessary re-renders
    const cookieInAppVersion = getCorrectValue(
      getCookie(CookieName.InAppVersion) || null,
      InAppVersion
    )
    if (cookieInAppVersion && inAppVersion !== cookieInAppVersion) {
      setInAppVersion(cookieInAppVersion)
    }

    const cookieDeviceId = getCorrectValue(
      getCookie(CookieName.DeviceId),
      DeviceId,
      UNKNOWN_DEVICE_ID
    )
    if (cookieDeviceId && cookieDeviceId !== deviceId) {
      setDeviceId(cookieDeviceId)
    }

    const cookieDeviceLocation = getCorrectValue(
      getCookie(CookieName.DeviceLocation),
      DeviceLocation,
      ""
    )
    if (cookieDeviceLocation && cookieDeviceLocation !== deviceLocation) {
      setDeviceLocation(cookieDeviceLocation)
    }

    const cookieUser = getUserFromCookie(getCookie(CookieName.User))
    if (typeof cookieUser !== "undefined" && !equals(user, cookieUser)) {
      setUser(cookieUser)
    }

    const cookieCommunityToken = getCommunityTokenFromCookie(
      getCookie(CookieName.CommunityToken)
    )
    if (
      typeof cookieCommunityToken !== "undefined" &&
      !equals(token, cookieCommunityToken)
    ) {
      setToken(cookieCommunityToken)
    }

    const cookieCountry = getCorrectValue(
      getCookie(CookieName.Country),
      Country,
      UNKNOWN_USER_COUNTRY
    )
    if (cookieCountry && cookieCountry !== country) {
      setCountry(cookieCountry)
    }

    const currentQueryParams = SessionQueryParamsRecord.safeParse({
      ...sessionQueryParams, // server-side passed via page props
      ...storageSessionQueryParams, // session storage ones
      ...Object.fromEntries(
        new URLSearchParams(window.location.search).entries()
      ), // possibly new stuff from URL
    })

    // store it in session storage - so it dies with a tab, not a browser like with session cookie
    if (currentQueryParams.success) {
      setSessionQueryParams(currentQueryParams.data)
    }

    // determine correct public app url in browser (can be hornet.com, hornetapp.com...)
    const newCurrentAppUrl = CurrentAppUrl.safeParse(
      `${window.location.protocol}//${window.location.host}`
    )
    if (newCurrentAppUrl.success && currentAppUrl !== newCurrentAppUrl.data) {
      setCurrentAppUrl(newCurrentAppUrl.data)
    }

    // add app store detection
    const canDeviceAccessAppStore =
      isIOS(window.navigator.userAgent) || isAndroid(window.navigator.userAgent)
    if (canDeviceAccessAppStore !== canAccessAppStore) {
      setCanAccessAppStore(canDeviceAccessAppStore)
    }

    // set the app start done flag - a little bit later so there's time for other
    // things to re-render and we're actually done
    setTimeout(() => {
      setIsAppStartDone(true)
    }, 100)
  })

  // trigger device ID detection
  useEffect(() => {
    if (deviceId === UNKNOWN_DEVICE_ID) {
      storeDeviceId(
        localStorageService.getItem<{
          d_id: string
        }>(StorageKey.misc).d_id
      )
    }
  }, [deviceId, localStorageService, storeDeviceId])

  // hook into router for pageview tracking
  useEffect(() => {
    if (router) {
      googleAnalyticsService.registerRouter(router)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  // anything that is dependent on the context setting from the `useEffectOnce`
  // hook goes here
  useEffect(() => {
    UseAppStartEmitter.emit("appStartDoneChanged", isAppStartDone)

    if (!isAppStartDone) {
      return
    }

    eventTrackerService.turnOn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppStartDone])
}
