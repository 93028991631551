import useTranslation from "next-translate/useTranslation"
import styled from "styled-components"
import { useRouter } from "next/router"
import { useSessionTotals } from "@hornet-web-react/core/hooks/use-session-totals"
import HornetAnalyticsEvent from "../../models/hornet-analytics-event"
import { MouseEvent } from "react"
import DiscoverActiveIcon from "@hornet-web-react/core/components/Icons/DiscoverActiveIcon"
import DiscoverIcon from "@hornet-web-react/core/components/Icons/DiscoverIcon"
import ChatIcon from "@hornet-web-react/core/components/Icons/ChatIcon"
import ChatActiveIcon from "@hornet-web-react/core/components/Icons/ChatActiveIcon"
import HomeActiveIcon from "@hornet-web-react/core/components/Icons/HomeActiveIcon"
import HomeIcon from "@hornet-web-react/core/components/Icons/HomeIcon"
import ShoppingCartActiveIcon from "@hornet-web-react/core/components/Icons/ShoppingCartActiveIcon"
import ShoppingCartIcon from "@hornet-web-react/core/components/Icons/ShoppingCartIcon"
import StoriesActiveIcon from "@hornet-web-react/core/components/Icons/StoriesActiveIcon"
import StoriesIcon from "@hornet-web-react/core/components/Icons/StoriesIcon"
import AppStoreBadges from "../AppStoreBadges"
import { UTM_SOURCE } from "@hornet-web-react/core/utils/constants"
import MenuItem from "./Menu/MenuItem"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import { useEmberAppUrl } from "../../hooks/use-ember-app-url"

type MenuProps = {
  className?: string
  onFinish?: () => void
}

const Menu = ({
  className,
  onFinish = () => {
    //
  },
}: MenuProps) => {
  const { t } = useTranslation()
  const { data: sessionTotals } = useSessionTotals()
  const { pathname, push } = useRouter()
  const { reportEvent } = useEventTrackerService()
  const emberAppUrl = useEmberAppUrl()

  const isFeedActive = /^(\/i)?\/feed/.test(pathname)
  const isStoriesActive = /^(\/i)?\/stories/.test(pathname)
  const isGuysActive =
    /^(\/i)?\/grids/.test(pathname) || /^(\/i)?\/profiles/.test(pathname)
  const isShopActive = /^(\/i)?\/shop/.test(pathname)
  const isChatActive = /^(\/i)?\/inbox/.test(pathname)

  const goToInbox = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    void reportEvent(HornetAnalyticsEvent.navigationTapOnChats())
    onFinish()
    await push("/inbox")
  }

  const goToFeed = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    void reportEvent(HornetAnalyticsEvent.navigationTapOnFeed())
    onFinish()
    await push("/feed")
  }

  const goToGuys = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    void reportEvent(HornetAnalyticsEvent.navigationTapOnGuys())
    onFinish()
    await push("/grids/nearby")
  }

  const goToShop = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    void reportEvent(HornetAnalyticsEvent.navigationTapOnShop())
    onFinish()
    await push("/shop")
  }

  return (
    <div className={className}>
      <MainMenuItems>
        <MenuItem
          href={"/feed"}
          label={t("hornet:layout.feed")}
          isActive={isFeedActive}
          itemIcon={<HomeIcon />}
          itemActiveIcon={<HomeActiveIcon />}
          onClick={goToFeed}
        />

        <MenuItem
          href={"/grids/nearby"}
          label={t("hornet:layout.discover")}
          itemIcon={<DiscoverIcon />}
          itemActiveIcon={<DiscoverActiveIcon />}
          isActive={isGuysActive}
          isExternal={false}
          onClick={goToGuys}
        />

        <MenuItem
          href={"/inbox"}
          label={t("hornet:layout.chat")}
          itemIcon={<ChatIcon />}
          itemActiveIcon={<ChatActiveIcon />}
          isActive={isChatActive}
          onClick={goToInbox}
          badge={sessionTotals?.unread_messages}
        />

        <MenuItem
          href={emberAppUrl + "/stories"}
          label={t("hornet:layout.find_stories")}
          itemIcon={<StoriesIcon />}
          itemActiveIcon={<StoriesActiveIcon />}
          isActive={isStoriesActive}
          isExternal={true}
          isRequiresAuth={false}
        />

        <MenuItem
          href={"/shop"}
          label={t("hornet:layout.shop")}
          itemIcon={<ShoppingCartIcon />}
          itemActiveIcon={<ShoppingCartActiveIcon />}
          isActive={isShopActive}
          onClick={goToShop}
        />
      </MainMenuItems>

      <MainMenuBadges
        utmSource={UTM_SOURCE.TOP_NAV}
        utmCampaign={null}
        small={true}
      />

      <MainMenuItems>
        <MenuItem
          href={emberAppUrl + "/contribute"}
          label={t("hornet:layout.contribute")}
          isActive={false}
          isExternal={true}
          isSmall={true}
          isRequiresAuth={false}
        />

        <MenuItem
          href={emberAppUrl + "/about"}
          label={t("hornet:layout.about")}
          isActive={false}
          isExternal={true}
          isSmall={true}
          isRequiresAuth={false}
        />

        <MenuItem
          href={emberAppUrl + "/support"}
          label={t("hornet:layout.support")}
          isActive={false}
          isExternal={true}
          isSmall={true}
          isRequiresAuth={false}
        />

        <MenuItem
          href={emberAppUrl + "/about/terms-of-service"}
          label={t("hornet:layout.tos")}
          isActive={false}
          isExternal={true}
          isSmall={true}
          isRequiresAuth={false}
        />

        <MenuItem
          href={emberAppUrl + "/about/privacy-policy"}
          label={t("hornet:layout.privacy_policy")}
          isActive={false}
          isExternal={true}
          isSmall={true}
          isRequiresAuth={false}
        />
      </MainMenuItems>
    </div>
  )
}

export default Menu

const MainMenuItems = styled.ul`
  transition: opacity 300ms ease-in-out;
  opacity: 1;

  display: block;
  margin: 0 0 ${({ theme }) => theme.spacing.less} 0;
  padding: 0;
  list-style: none;
`

const MainMenuBadges = styled(AppStoreBadges)`
  margin: 0 0 ${({ theme }) => theme.spacing.regular} 0;
  justify-content: flex-start;
`
