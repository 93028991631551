// on what 1st level domains this app can be served - for CSP
const allowedFirstLevelDomains = [
  "hornet.com",
  "hornetapp.com",
  "ruhornetapp.com", // make sure the fallback is always last
]

const allowedAppDomains = allowedFirstLevelDomains.map(
  (domain) => `next.${domain}`
)

if (process.env.NEXT_PUBLIC_APP_ENV === "development") {
  allowedAppDomains.push("localhost:3000")
  allowedAppDomains.push("h1.dev.test:3000")
  allowedAppDomains.push("h2.dev.test:3000")
}

const ipQualityScoreDomains = {
  "next.hornet.com":
    "https://www.ipqscdn.com/api/next.hornet.com/wrse96n9vXo6OYYNafiYsYrCQQahbyXHRv1ygUMDGHre6w3w5XfAuZ3NucIdKDbsNmDsdoqTeOGchWZD80LO6GJFjV78wmyt9JEVws41e4Er46Ahd3sszaDfWWEnIAJD5RoulgElWn5gfr6sKVYr5f37mBpbr1OQ15AUFSDYzSfvq67hs5Ahpd1M0EGDW5tEi1wFjsw5DHlpeHYCAHcpYAGurSoHUTHxQEokM2jqeBVRFNJez01yqKxEXTwPOmJw/learn.js",
  "next.hornetapp.com":
    "https://www.ipqscdn.com/api/next.hornetapp.com/sMv5Kq00SuAtoc4CXLac6nbF05hp3LTeHLScPQLBID5IZzjGgoJnYkrM7X371l31ENm44kRQvFno9NrZY7GgRbjjZuIVObHH3QD8F178eSUcWlc0NF0886Q3Y2Ag6JaTdFMwA4IdCRGd28Zfw5SO9V76HaLCuAAP5MPeD1vXDBL3O905bbJAQMPplKuhuiMeqp1uuzsa0WbSsssTRfyOrXaRGn35qtfOtUT2zOxEfP0iHg5fm2kyx1qrMT3wKg87/learn.js",
  "next.ruhornetapp.com":
    "https://www.ipqscloud.com/api/next.ruhornetapp.com/CVB9QWspnL0yhrqEYmJRyjnigvT7sTtAEM0ULETdGlMOgcGgviXnIybH6pVm7Dck8de3CjrtdWaqLhLaISTbe73PSTYxih9vmdgQCUjkFlKyoRbgAbWVIolNxXr5bGTFh9lyq80aNOio0RSEWMaejWIoeyT6qAqjAe4zf5AqL1PrFRipbZd8zZ9sd9KRx2FiZxDhu3qvE6k0g0JoH4w7CAtwCmZ9ItseeAjQ5STkcD8ki7uQhUE1DEG0etRiUzCc/learn.js",
  "hornet.alpha.unicornbooty.com":
    "https://www.ipqscdn.com/api/hornet.alpha.unicornbooty.com/3Wzxi9T04GSg10nS4rwuQxtQPuWxBk1TMmHQajCAQxwSZyLVr1BLgrS1dp6C6skXLZ7EuALDzo10BIozABPUccPDDR7XoSjF55udXoUMKKUOSYTh5H6Svr1TzUnLZhoREE6bzjmpbGrGNCALToLmC46wZtTfE3eFBXC3qXzEthWRTnchWvz0PXsdYRjOkUmcvpRt2RhwxAMHDyK906fo37NTYAjxjDp5tcKBxA0hx1VyTHCDiO3QxTfzLNyVa0MH/learn.js",
  "hornet.beta.unicornbooty.com":
    "https://www.ipqscdn.com/api/hornet.beta.unicornbooty.com/0ONTlSWsOFw8Fb5kW5TQ8p45fyXlzWqMrsaoXuOKrIlgkNk64SLPaDEUvL7AmcDQyYStLhT0KppDZuPlXjTMyQA3ZehCYAr8k3Xg8MFykIjXeABzteXHUnf2S9aObnC2PqjuEeB3ND7wDq6ZJpLGPnQaBgVT3uL57CkAIdHjHI1zJyrrVlSH80iVRLAGXOKXdGvzRZF9rwFKVOKuPrNiu7Xx3r8N9ccOX9pWHBv3ErnsuLQveH5papqxTnGAuYW3/learn.js",
  localhost:
    "https://www.ipqscdn.com/api/hornet.alpha.unicornbooty.com/3Wzxi9T04GSg10nS4rwuQxtQPuWxBk1TMmHQajCAQxwSZyLVr1BLgrS1dp6C6skXLZ7EuALDzo10BIozABPUccPDDR7XoSjF55udXoUMKKUOSYTh5H6Svr1TzUnLZhoREE6bzjmpbGrGNCALToLmC46wZtTfE3eFBXC3qXzEthWRTnchWvz0PXsdYRjOkUmcvpRt2RhwxAMHDyK906fo37NTYAjxjDp5tcKBxA0hx1VyTHCDiO3QxTfzLNyVa0MH/learn.js",
}

const hornetDomains = allowedFirstLevelDomains.join(" ")
const hornetDomainsWildcard = allowedFirstLevelDomains
  .map((domain) => `*.${domain}`)
  .join(" ")
const sentryAllowUrls = [
  "chunk",
  ...allowedAppDomains.map((domain) => `https://${domain}`),
]

const googleDomainsForImgSrcCsp = [
  "www.google.nl",
  "www.google.co.id",
  "www.google.com.tr",
  "www.google.ru",
  "www.google.de",
  "www.google.fr",
  "www.google.co.th",
  "www.googletagmanager.com",
].join(" ")

const ENV = {
  environment: process.env.NEXT_PUBLIC_APP_ENV || "production",

  version: "2.25.0",

  appName: "hornet",

  // use for canonical URLs and as base value
  appUrl: "https://next.hornet.com",
  appUrlProtocol: "https",

  allowedAppDomains,
  allowedFirstLevelDomains,

  hornet_web_url: "https://hornet.com",

  appStore: {
    ios: {
      appStoreId: "462678375",
      appId: "com.gethornet.Hornet",
      appName: "Hornet - Queer Social Network",
    },
    android: {
      playStoreId: "com.hornet.android",
    },
  },

  auth: {
    facebook: {
      appId: "567246033392315",
      scope: "email",
      version: "v16.0",
      fbxml: false,
    },
    google: {
      clientId:
        "961342034224-6to63nnkhuomgs4tinami7hl2ulpmpvs.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/userinfo.email",
    },
    sso: {
      isMinimal: true,
    },
  },

  aws: {
    cognito: {
      identityPoolId: "us-east-1:f209f4c4-62e3-4c83-9d16-9c25ef57c546",
    },
    region: "us-east-1",
  },

  contentSecurityPolicy: `
    base-uri 'self';
    object-src 'none';
    default-src 'self';
    frame-src 'self' *.google.com *.googlesyndication.com *.stripe.com *.googletagservices.com *.doubleclick.net ${hornetDomains};
    script-src 'self' www.googletagmanager.com js.stripe.com www.google.com www.gstatic.com connect.facebook.net accounts.google.com www.ipqscdn.com *.doubleclick.net *.googlesyndication.com www.googletagservices.com;
    child-src 'self' blob: js.stripe.com www.google.com accounts.google.com *.googlesyndication.com;
    style-src 'self' accounts.google.com;
    font-src 'self';
    worker-src 'self' blob:;
    img-src 'self' data: blob: hornet-static.s3.amazonaws.com ghph-development.s3.amazonaws.com akamai.gethornet.com cdn.hnetworkmedia.com www.google-analytics.com ${hornetDomains} api.mapbox.com gethornet.com *.googlesyndication.com *.google.com ${googleDomainsForImgSrcCsp};
    media-src 'self' data: blob: hornet-static.s3.amazonaws.com ghph-development.s3.amazonaws.com akamai.gethornet.com cdn.hnetworkmedia.com ${hornetDomains} gethornet.com;
    connect-src 'self' *.unicornbooty.com *.gethornet.com gethornet.com *.sqaces.com sqaces.com ${hornetDomains} ${hornetDomainsWildcard} *.google-analytics.com firebase.googleapis.com firebaseinstallations.googleapis.com o289177.ingest.sentry.io api.mapbox.com events.mapbox.com analytics.google.com *.analytics.google.com doubleclick.net *.facebook.com wss://pubsub.staging.gethornet.com:8084/mqtt wss://pubsub.gethornet.com:8084/mqtt wss://pubsub.sqaces.com:8084/mqtt ipqscdn.com *.ipqscdn.com ipqsnet.com *.ipqsnet.com *.doubleclick.net *.googlesyndication.com *.gstatic.com fundingchoicesmessages.google.com ${googleDomainsForImgSrcCsp} *.amazonaws.com wss://*.amazonaws.com www.google.com;
  `,
  // HACK: ignore CSP from Sentry for now
  // report-uri https://o289177.ingest.sentry.io/api/4504004558651392/security/?sentry_key=8f1bf426b547460b904392a63da94203;
  // report-to csp-endpoint

  defaultLocale: "en",
  supportedLocale: ["en"],

  eventTracking: {
    url: "https://hornetapp.com/webanalytics/",
    trackerName: "hornet",
  },

  firebase: {
    isEnabled:
      ["alpha", "beta"].includes(process.env.NEXT_PUBLIC_APP_ENV) &&
      !process.env.NEXT_PUBLIC_IS_TESTING, // prod goes to BQ tracker only
    isDebug: !["production"].includes(process.env.NEXT_PUBLIC_APP_ENV),
    apps: {
      default: {
        apiKey: "AIzaSyA6kvUjbrWTwVJKEwLh851eEWzbNx-kRm0",
        authDomain: "hornet-76175.firebaseapp.com",
        databaseURL: "https://hornet-76175.firebaseio.com",
        projectId: "hornet-76175",
        storageBucket: "hornet-76175.appspot.com",
        messagingSenderId: "344404118766",
        appId: "1:344404118766:web:b94cbb550ee6b4d92737ff",
        measurementId: "G-L6Y4LH3B1S",
      },
      analytics: {},
    },
    logLevel: "silent", // debug | error | silent
    remoteConfigFetchInterval: 2 * 60 * 60 * 1000, // every 5 minutes
  },

  firebaseDynamicLink: {
    baseUrl: "",
  },

  googleAnalytics: {
    isDebug: !["production"].includes(process.env.NEXT_PUBLIC_APP_ENV),

    // Google Analytics 4
    ga4: {
      isEnabled:
        ["production", "alpha", "beta"].includes(
          process.env.NEXT_PUBLIC_APP_ENV
        ) && !process.env.NEXT_PUBLIC_IS_TESTING,

      id: ["production", "beta"].includes(process.env.NEXT_PUBLIC_APP_ENV)
        ? "G-4LXQHENHXP"
        : "G-ECPJMQ43RB",
    },
  },

  googleRecaptcha: {
    siteKey: "6Lfki8gqAAAAAF0MXVd4pMyjczsyxAdWjuF31ppS",
  },

  ipQualityScore: {
    isEnabled: true,
    scriptSrcDomainMap: ipQualityScoreDomains,
  },

  hornet_api: {
    host: "https://gethornet.com",
    namespace: "api/v3",
    fallbacks: ["https://api.sqaces.com"],
  },

  hornet_shop_api: {
    host: "https://hornetapp.com/community/wp-json",
    namespace: "wc",
    is_dev: false,
    fallbacks: ["https://ruhornetapp.com/community/wp-json"],
  },

  community_api: {
    host: "https://hornetapp.com/community/wp-json",
    namespace: "",
    is_dev: false,
    fallbacks: ["https://ruhornetapp.com/community/wp-json"],
  },

  quickies_api: {
    host: "",
    namespace: "",
    fallbacks: [],
  },

  simpleCookieConfig: () => ({
    // domain?: string;
    // encode?(val: string): string;
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 7 * 50 * 1000), // 50 weeks
    httpOnly: false, // we want to access the cookie via JS in client
    path: "/", // root of app
    sameSite: "lax", // `lax` so we can use Payssion cookie redirect
    secure: process.env.NEXT_PUBLIC_APP_ENV !== "development",
  }),

  sentry: {
    isEnabled:
      !process.env.NEXT_PUBLIC_IS_TESTING &&
      !process.env.STORYBOOK &&
      ["production", "beta", "alpha"].includes(process.env.NEXT_PUBLIC_APP_ENV),
    dsn: "https://8f1bf426b547460b904392a63da94203@o289177.ingest.sentry.io/4504004558651392",
    debug: false,
    release: "", // set at EOF
    allowUrls: sentryAllowUrls,
    tracesSampleRate: ["production", "beta"].includes(
      process.env.NEXT_PUBLIC_APP_ENV
    )
      ? 0.001
      : 1,
    sampleRate: ["production", "beta"].includes(process.env.NEXT_PUBLIC_APP_ENV)
      ? 1
      : 1,
  },

  mapbox: {
    accessToken:
      "pk.eyJ1IjoiaG9ybmV0bmV0d29ya3MiLCJhIjoiY2p5ZWZ2cDBrMDFlczNubzZpeDU1cTB0NSJ9.JPbI1psOZfPcA6ClgLJGMA",
    mapboxGlJsVersion: "2.5.1",
    mapboxGlGeocoderVersion: "4.7.2",
  },

  stripe: {
    publishableKey: "pk_live_dBAn0ZWTnS90AiuNVsAZrCTn",
  },

  payssion: {
    payment_url: "https://www.payssion.com/checkout/live_893f44f96cbbcded",
  },

  payssionEnabledCountries: ["id", "br"],

  defaultPaymentMethod: {
    id: "payssion",
    br: "payssion",
  },

  mqtt: {
    host: "wss://pubsub.gethornet.com:8084/mqtt",
  },

  publicApiIpCountryKey: "39ef23f6c0722ad98ea76916f30a5605d019f817",

  quickies: {
    appUrl: "https://hornetx.com",
  },
}

ENV.sentry.release = ENV.version

if (["development", "alpha", "beta"].includes(ENV.environment)) {
  ENV.auth.facebook.appId = "567246696725582"

  // Hornet Web Alpha stream
  ENV.firebase.apps.default.appId = "1:344404118766:web:564cf932f6e74f382737ff"
  ENV.firebase.apps.default.measurementId = "G-Z5P17XVNCE"

  ENV.firebase.logLevel = "debug"
  ENV.firebase.remoteConfigFetchInterval = 10 * 60 * 1000 // every 10 minutes

  ENV.googleRecaptcha.siteKey = "6Lfji8gqAAAAAFwNl9UTUM8YQfnuxjtGK77DXtEj"

  // ignored when served via <meta>
  // ENV.contentSecurityPolicy["report-uri"] =
  //   "https://o289177.ingest.sentry.io/api/1526217/security/?sentry_key=ef6ad00455c445439d83af3efadd386d&sentry_environment=ENVIRONMENT&sentry_release=RELEASE;"
  // ENV.contentSecurityPolicy["report-to"] = "sentry_csp_endpoint"

  ENV.mapbox.accessToken =
    "pk.eyJ1IjoiaG9ybmV0bmV0d29ya3MiLCJhIjoiY2t2ODY0OXoyMm02dTJybHBqbWptb2podyJ9.yhX3-1n8tcdSxcXP0x9C_Q"
  // add an incremental number to the release so Sentry does not get confused with source maps
  ENV.sentry.release = ENV.version + "-" + process.env.RUN_NUMBER

  ENV.publicApiIpCountryKey = "fe2e0e9d695cb4c07173f00797e146307743b638"
}

if (ENV.environment === "development") {
  ENV.appUrl = "https://localhost:3000"
  ENV.hornet_web_url = "https://localhost:4200"

  ENV.hornet_api.host = "https://alpha.gethornet.com"
  ENV.hornet_api.fallbacks = []
  ENV.hornet_shop_api.host = "http://localhost:8080"
  ENV.hornet_shop_api.host = "https://fargate-service.unicornbooty.com"
  ENV.hornet_shop_api.is_dev = true
  ENV.community_api.host = "http://localhost:8080"
  ENV.community_api.host = "https://fargate-service.unicornbooty.com"
  ENV.community_api.is_dev = true

  ENV.quickies.appUrl = "https://localhost:3000"

  // comment this out to test it locally with
  // `nx build hornet && nx run hornet:serve:devprod`
  ENV.sentry.isEnabled = false
  delete ENV.sentry.allowUrls
  ENV.sentry.debug = true

  ENV.stripe.publishableKey = "pk_test_OMrT1BB4v1rX1Clh4xCcQDW2"

  ENV.payssion.payment_url =
    "https://sandbox.payssion.com/checkout/sandbox_ad31a437c894c275"

  ENV.mqtt.host = "wss://pubsub.staging.gethornet.com:8084/mqtt"

  ENV.aws.cognito.identityPoolId =
    "us-east-1:3efcef4b-1e20-4378-8d38-be8b4160d899"
}

if (ENV.environment === "alpha") {
  ENV.appUrl = "https://hornet.alpha.unicornbooty.com"
  ENV.hornet_web_url = "https://alpha.front.gethornet.com"

  ENV.hornet_api.fallbacks = []
  ENV.hornet_api.host = "https://alpha.gethornet.com"
  ENV.hornet_shop_api.host = "https://fargate-service.unicornbooty.com"
  ENV.hornet_shop_api.is_dev = true
  ENV.community_api.host = "https://fargate-service.unicornbooty.com"
  ENV.community_api.is_dev = true

  ENV.quickies.appUrl = "https://quickies.alpha.unicornbooty.com"

  ENV.sentry.allowUrls = ["chunk", "https://hornet.alpha.unicornbooty.com"]

  ENV.mapbox.accessToken =
    "pk.eyJ1IjoiaG9ybmV0bmV0d29ya3MiLCJhIjoiY2p5ZWZ1bDFvMTIwZDNwbzM2MHAyNWw3diJ9.d2eFM5_DoDgoG2k0RywJtw"

  ENV.stripe.publishableKey = "pk_test_OMrT1BB4v1rX1Clh4xCcQDW2"

  ENV.payssion.payment_url =
    "https://sandbox.payssion.com/checkout/sandbox_ad31a437c894c275"

  ENV.mqtt.host = "wss://pubsub.staging.gethornet.com:8084/mqtt"

  ENV.aws.cognito.identityPoolId =
    "us-east-1:3efcef4b-1e20-4378-8d38-be8b4160d899"
}

if (ENV.environment === "beta") {
  ENV.auth.facebook.appId = "567246033392315"

  ENV.appUrl = "https://hornet.beta.unicornbooty.com"
  ENV.hornet_web_url = "https://beta.front.gethornet.com"

  ENV.hornet_api.host = "https://beta.gethornet.com"

  ENV.googleRecaptcha.siteKey = "6Lfki8gqAAAAAF0MXVd4pMyjczsyxAdWjuF31ppS"

  ENV.sentry.allowUrls = ["chunk", "https://hornet.beta.unicornbooty.com"]

  ENV.mapbox.accessToken =
    "pk.eyJ1IjoiaG9ybmV0bmV0d29ya3MiLCJhIjoiY2p5ZWZ1eXM3MDdqZTNtcG9rNzJhYjAzMCJ9.OEQ1blpjD9i4tF_9nRnm0w"

  // Hornet Web Beta stream
  ENV.firebase.apps.default.appId = "1:344404118766:web:7923b0b4a06aa0d82737ff"
  ENV.firebase.apps.default.measurementId = "G-3JK7Y4T4Z8"
}

// some CI-related hacks
if (process.env.NEXT_PUBLIC_IS_TESTING) {
  // HACK: for lighthouse CI, this is the app url
  ENV.appUrl = "http://localhost:3000"
  ENV.appUrlProtocol = "http"

  // HACK: make CI use staging DB, otherwise it's localhost setup
  ENV.hornet_shop_api.host = "https://fargate-service.unicornbooty.com"
  ENV.community_api.host = "https://fargate-service.unicornbooty.com"
}

module.exports = ENV
