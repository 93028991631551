import { useCallback } from "react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import HornetAnalyticsEvent from "../models/hornet-analytics-event"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"

export const useEventTrackerService = () => {
  const { currentUser } = useSessionUser()
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  return {
    reportEvent: useCallback(
      async (analyticsEvent: HornetAnalyticsEvent) => {
        const eventWithPremiumFlag = analyticsEvent.addParams([
          {
            key: "has_premium",
            string_value: currentUser?.hasPremium ? "yes" : "no",
          },
        ])

        return eventTrackerService.report(eventWithPremiumFlag)
      },
      [currentUser?.hasPremium, eventTrackerService]
    ),
  }
}
