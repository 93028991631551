import { z } from "zod"
import { ReactNode } from "react"

export type NavigationAction = {
  label: string
  onClick: () => void
  hasChevron?: boolean
  isDisabled?: boolean
}

export type NavigationLink = {
  label: string
  href: string
  hasChevron?: boolean
  onClick?: () => void
}

export type UseHookResult = {
  isLoading: boolean
  error: Error | undefined
}

export type ActionSheetModalItemType = {
  id: string
  icon: ReactNode
  label: string
  isDanger?: boolean
  isDisabled?: boolean
  action?: () => void
}

export enum CtaAction {
  submit = "submit",
  close = "close",
}

export enum ModalSize {
  half = "half",
  full = "full",
}

export enum HoneyPaywall {
  FeaturesList = "features_list",
  FeaturesShoppingCart = "features_shopping_cart",
  FeaturesActive = "features_active",
  FeaturesNoBalance = "features_no_balance",
}

export enum ResponseHeaderName {
  InAppVersion = "x-in-app-version",
  Locale = "x-app-locale",
  DeviceId = "x-device-id",
  User = "x-user",
  Country = "x-country",
  CommunityToken = "x-cmnty-token",
  DebugOtpMs = "x-otp-ms",
  DebugMiddlewareMs = "x-middleware-ms",
  DeviceLocation = "x-device-location",
  TimeZone = "x-timezone",
  AppDomain = "x-app-domain",
}

export enum CookieName {
  InAppVersion = "hrnt_inAppVersion",
  Locale = "hrnt_locale",
  DeviceId = "hrnt_deviceId",
  User = "hrnt_user",
  Country = "hrnt_country",
  CommunityToken = "hrnt_cmntyToken",
  UtmSource = "hrnt_utmSource",
  UtmMedium = "hrnt_utmMedium",
  UtmCampaign = "hrnt_utmCampaign",
  PostNudes = "hrnt_postNudes", // deprecated
  XReferrer = "hrnt_xReferrer",
  IgnoreNotifications = "hrnt_ignoreNotifs", // deprecated
  DeviceLocation = "hrnt_deviceLocation",
  IsPwaFresh = "hrnt_isPwaFresh",
  IntroducingGroups = "hrnt_introducingGroups",
  ActionPanelRegisterAnon = "hrnt_actionRegisterAnon",
  ActionPanelInstallPwa = "hrnt_actionInstallPwa",
  ActionPanelEnableNotifications = "hrnt_actionEnableNotifications",
  ActionIsOnTrial = "hrnt_actionIsOnTrial",
  AppDomain = "hrnt_appDomain",
}

export const PremiumPaywall = z.enum([
  // official but unused
  "height_filter",
  "looking_for_filter",
  "only_online_filter",
  "privacy_invisible",
  "profile",
  "relationship_filter",
  "search_new_guys",
  "search_who_checked_you_out",
  "sexual_role_filter",
  "weight_filter",
  "rewarded_video", // deprecated

  // using
  "side_filters", // SideFilters
  "feed_new_guys_carousel", // TODO: Feed
  "feed_waiting_for_response_carousel", // TODO: Feed
  "feed_who_checked_you_out_carousel", // TODO: Feed
  "menu", // BottomNavBar
  "grid_member_cell", // GridMember
  "new_guys", // GridMember
  "settings", // PageAccount
  "profile_invisible", // BottomNavBar
  "photos_addphoto", // ProfileGalleryPremiumTile, EditProfile[Public/Private]Photos
  "who_checked_you_out", // GuysSearchModal, PageMembersGrid
  "ad_tapremove", // AdUnit
  "read_receipts", // ChatBody
  "hook", // TODO:
  "unknown", // ?
  "hornet_badge_progress", // will be deprecated with HornetBadge
  "cancelling_account", // maybe TODO: DeleteAccountModal

  "inboxes", // InboxFoldersModal
  "delete_message", // use-messenger

  // soft paywalls
  "s_conversations_daily_limit", // TODO: LS counter
  "s_profiles_full_daily_limit", // not used
  "s_profiles_modal_daily_limit", // TODO: LS counter
  "s_explore_location_limit", // GuysSearchBar
  "s_grid_forced_paywall", // TODO: in Russia only (non-badge holders aka non-verified)

  // query params
  "native_ad", // deprecated: renamed to house ads
  "house_ad", // house ads
])
export type PremiumPaywall = z.infer<typeof PremiumPaywall>

export const QuickiesPremiumPaywall = z.enum([
  "map_profile",
  "blurred_map",
  "blurred_chat",
  "blurred_conversation",
  "blurred_groups",
  "navbar_chat",
  "navbar_groups",
  "dick_verification",
  "delete_message",
  "map_trial_panel",
])
export type QuickiesPremiumPaywall = z.infer<typeof QuickiesPremiumPaywall>

export const QuickiesProfileId = z.coerce
  .string()
  .regex(/[0-9]{1,16}/)
  .brand<"QuickiesProfileId">()
export type QuickiesProfileId = z.infer<typeof QuickiesProfileId>

// Hornet profile ID (Hornet X profile ID in Quickies)
export const HornetProfileId = z.coerce
  .string()
  .regex(/[0-9]{1,16}/)
  .brand<"HornetProfileId">()
export type HornetProfileId = z.infer<typeof HornetProfileId>

export const QuickiesPointOfInterestId = z.coerce
  .string()
  .brand<"QuickiesPointOfInterestId">()
export type QuickiesPointOfInterestId = z.infer<
  typeof QuickiesPointOfInterestId
>

export const QuickiesGroupId = z.coerce
  .string()
  .regex(/[0-9]{1,16}/)
  .brand<"QuickiesGroupId">()
export type QuickiesGroupId = z.infer<typeof QuickiesGroupId>

export const QuickiesGroupMembershipId = z.coerce
  .string()
  .regex(/[0-9]{1,16}/)
  .brand<"QuickiesGroupMembershipId">()
export type QuickiesGroupMembershipId = z.infer<
  typeof QuickiesGroupMembershipId
>

export const InviteCode = z.string().min(1).brand("InviteCode")
export type InviteCode = z.infer<typeof InviteCode>

export const QuickiesGroupChatId = z.coerce
  .string()
  .regex(/[0-9]{1,16}/)
  .brand<"QuickiesGroupChatId">()
export type QuickiesGroupChatId = z.infer<typeof QuickiesGroupChatId>

export const QuickiesGroupChatMessageId = z.coerce
  .string()
  .uuid()
  .brand<"QuickiesGroupChatMessageId">()
export type QuickiesGroupChatMessageId = z.infer<
  typeof QuickiesGroupChatMessageId
>

export const QuickiesGroupChatMembershipId = z.coerce
  .string()
  .regex(/[0-9]{1,16}/)
  .brand<"QuickiesGroupChatMembershipId">()
export type QuickiesGroupChatMembershipId = z.infer<
  typeof QuickiesGroupChatMembershipId
>
