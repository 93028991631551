import { Platform, PLATFORM } from "@hornet-web-react/core/utils/constants"
import { devAssert, isClient } from "@hornet-web-react/core/utils"
import { HornetProfileId } from "@hornet-web-react/core/types"
import { Locale } from "@hornet-web-react/core/types/session"

export interface TrackEventParam {
  key: string
  string_value?: string
  int_value?: number
}

export type TrackEventParams = TrackEventParam[]
export type HornetAnalyticsFormat = [string, TrackEventParams]
export type FirebaseAnalyticsParam = Record<string, string | number>

// HACK: remember the current platform if it was ever existent for time
// of this session
let currentPlatform = ""

export default class TrackEvent {
  name: string
  params: TrackEventParams

  constructor(name: string, params: TrackEventParams = []) {
    this.name = name
    this.params = params
  }

  addParams(params: TrackEventParams) {
    return new TrackEvent(this.name, [...this.params, ...params])
  }

  toHornetAnalytics(
    isInApp: boolean,
    locale: Locale,
    platform?: string
  ): HornetAnalyticsFormat {
    return [
      this.name,
      this.addExtraParams(this.params, isInApp, locale, platform),
    ]
  }

  private addExtraParams(
    params: TrackEventParams,
    isInApp: boolean,
    locale: Locale,
    platform?: string
  ) {
    if (!isClient) {
      return params
    }

    const newParams = [
      ...params,
      { key: "in_app", string_value: isInApp ? "yes" : "no" },
      { key: "web_version", string_value: "v2" },
      { key: "locale", string_value: locale },
    ]

    // on client, add always extra param with platform

    // we saved it for this session so we have it
    if (currentPlatform) {
      return [...newParams, { key: "platform", string_value: currentPlatform }]
    }

    if (typeof platform !== "undefined" && ["ios", "drd"].includes(platform)) {
      currentPlatform = platform
      return [...newParams, { key: "platform", string_value: currentPlatform }]
    }

    return newParams
  }

  static sessionStart() {
    return new TrackEvent("Session_start")
  }

  static pageLoaded(routerPathname: string, hasPremium: boolean) {
    return new TrackEvent("Page_loaded", [
      { key: "router_pathname", string_value: routerPathname },
      { key: "has_premium", string_value: hasPremium ? "yes" : "no" },
    ])
  }

  static pageViewed(routerPathname: string, hasPremium: boolean) {
    return new TrackEvent("Page_viewed", [
      { key: "router_pathname", string_value: routerPathname },
      { key: "has_premium", string_value: hasPremium ? "yes" : "no" },
    ])
  }

  static appDownload(appStore: Platform, origin: string) {
    const validStores = [PLATFORM.IOS, PLATFORM.ANDROID]

    devAssert(
      "AppDownload method must be valid appStore",
      validStores.includes(appStore)
    )

    return new TrackEvent("Ad_tapOnDownloadApp", [
      { key: "app_store", string_value: appStore },
      { key: "origin", string_value: origin },
    ])
  }

  static guyTapOnFollow(profileId: HornetProfileId) {
    return new TrackEvent("Guy_tapOnFollow", [
      { key: "profile_id", string_value: profileId },
    ])
  }

  static guyTapOnUnfollow(profileId: HornetProfileId) {
    return new TrackEvent("Guy_tapOnUnfollow", [
      { key: "profile_id", string_value: profileId },
    ])
  }

  static guyTapOnBlock() {
    return new TrackEvent("Guy_tapOnBlock")
  }

  static welcomeTapAddProfilePhoto() {
    return new TrackEvent("Welcome_tapAddProfilePhoto")
  }

  static modalClosed(modalName: string) {
    return new TrackEvent("Modal_closed", [
      {
        key: "modal",
        string_value: modalName,
      },
    ])
  }

  static modalOpened(modalName: string) {
    return new TrackEvent("Modal_opened", [
      {
        key: "modal",
        string_value: modalName,
      },
    ])
  }

  static hornetIpQualityCheckFailed(reason: string, screen: string) {
    return new TrackEvent("HornetIpQualityCheck_failed", [
      {
        key: "reason",
        string_value: reason,
      },
      {
        key: "screen",
        string_value: screen,
      },
    ])
  }
}
