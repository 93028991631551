import ApiService, {
  type ApiRequest,
  type ApiServiceContext,
  type Headers,
} from "./ApiService"
import { debug } from "@hornet-web-react/core/utils"
import InsufficientBalanceError from "./Errors/InsufficientBalanceError"
import { CustomStripeError } from "./Errors/CustomStripeError"
import {
  CommunityApiServiceEndpoint,
  CommunityApiServiceEndpointType,
} from "./CommunityApiServiceEndpoint"
import { ApiServiceEndpointType } from "./ApiServiceEndpoint"
import { AppConfig } from "../AppConfig"
import LoggerService from "../LoggerService"
import { UNKNOWN_DEVICE_ID } from "@hornet-web-react/core/utils/constants"
import LocalStorageService from "../LocalStorageService"
import { CommunityApiToken } from "@hornet-web-react/core/types/session"

export interface CommunityApiServiceContext extends ApiServiceContext {
  communityToken: CommunityApiToken | null
  setCommunityToken: (communityToken: CommunityApiToken) => void
}

export type CommunityApiServiceFactory = (
  context: CommunityApiServiceContext
) => CommunityApiService

class CommunityApiService extends ApiService {
  protected override _context!: CommunityApiServiceContext
  private _hasFetchedFreshToken = false

  constructor(
    appConfig: AppConfig,
    loggerService: LoggerService,
    localStorageService: LocalStorageService,
    context: CommunityApiServiceContext
  ) {
    super(appConfig, loggerService, localStorageService, context)
    this.updateContext(context)
  }

  override updateContext(context: CommunityApiServiceContext) {
    this._context = {
      ...context,
      deviceId:
        context.deviceId === UNKNOWN_DEVICE_ID
          ? this._fallbackDeviceId
          : context.deviceId,
    }
  }

  /**
   * Essentially a relay for SSR purposes, see hack in `with-session` middleware
   */
  get communityToken() {
    return this._context.communityToken
  }

  async getFreshCommunityToken(isForced = false): Promise<void> {
    // we already have a fresh token
    if (this._hasFetchedFreshToken && !isForced) {
      debug(
        `CommunityApiService: getFreshCommunityToken: skipped (fresh token present)`
      )
      return
    }

    // or we cannot even get one
    if (!this._context.accessToken) {
      debug(
        `CommunityApiService: getFreshCommunityToken: skipped (no access token)`
      )
      return
    }

    // maybe there's valid token from cookie etc
    if (this.communityToken && !isForced) {
      const currentDate = new Date()
      if (currentDate < new Date(this.communityToken.validUntil)) {
        debug(
          `CommunityApiService: getFreshCommunityToken: skipped (valid token present)`
        )
        return
      }
    }

    debug(`CommunityApiService: getFreshCommunityToken: fetch`)

    const queryParams = new URLSearchParams({
      access_token: this._context.accessToken,
    })

    const tokenData = await this.useEndpoint<{
      community_token: string
    }>(
      CommunityApiService.getCommunityApiEndpoint(
        CommunityApiServiceEndpoint.CommunityLoginPost,
        [queryParams.toString()]
      ),
      {}
    )

    if (typeof tokenData?.community_token !== "undefined") {
      // set expiry to 1hr, then re-fetch
      const validUntil = new Date()
      validUntil.setHours(validUntil.getHours() + 1)

      this._context.communityToken = {
        token: tokenData.community_token,
        validUntil: validUntil.toISOString(),
      }

      this._hasFetchedFreshToken = true

      debug(`CommunityApiService: getFreshCommunityToken: fetch successful`)

      this._context.setCommunityToken(this._context.communityToken)
    }
  }

  static getCommunityApiEndpoint(
    endpoint: CommunityApiServiceEndpointType,
    params: string[]
  ): ApiServiceEndpointType {
    return ApiService.getEndpoint(endpoint, params)
  }

  /**
   * Override with some community-specific errors
   * @param status
   * @param headers
   * @param body
   * @param request
   */
  override async handleResponse(
    status: number,
    headers: HeadersInit,
    body: any,
    request: ApiRequest
  ) {
    const isCommunityTokenExpired = (status: number, responseCode?: string) => {
      return (
        status === 403 ||
        (status === 401 &&
          ["rest_not_logged_in", "woocommerce_rest_cannot_view"].includes(
            responseCode || ""
          ))
      )
    }

    // we can try fetching the community token in this error and retry the request
    // in case we haven't done that already - only if we have access token
    if (
      isCommunityTokenExpired(status, body?.code) &&
      !request.isRetry &&
      this._context.accessToken
    ) {
      debug(`CommunityApiService: handleResponse, refresh token and retry`)

      this._context.communityToken = null

      await this.getFreshCommunityToken(true)

      // seems like success
      if (this._context.communityToken !== null) {
        const retryRequest: ApiRequest = {
          ...request,
          isRetry: true,
          params: {
            ...request.params,
            headers: this.getHeaders(request.requestUrl.url),
          },
        }

        return this.makeRequest(retryRequest)
      }
    }

    // custom stripe error handling
    if (
      [400, 500].includes(status) &&
      body?.error?.message &&
      body?.error?.type
    ) {
      throw this.decorateError(
        new CustomStripeError(
          body.error.message,
          body.error.type,
          body.error.decline_code
        ),
        status,
        request
      )
    }

    if (402 === status) {
      throw this.decorateError(new InsufficientBalanceError(), status, request)
    }

    return super.handleResponse(status, headers, body, request)
  }

  protected override getHeaders(url: string): Headers {
    const headers = super.getHeaders(url)

    // HACK: do not append our Community token if this is a login endpoint call
    // because we need the original Hornet authorization there
    if (
      this._context.communityToken !== null &&
      !url.match(/hornet\/v1\/login/)
    ) {
      headers["Authorization"] = `Bearer ${this._context.communityToken.token}`
    }

    // not supported by Lumen
    delete headers["X-Timezone"]

    return headers
  }
}

export default CommunityApiService
