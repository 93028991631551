// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"2.25.0"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs"
import config from "./src/config"
import { globalHandlersIntegration } from "@sentry/browser"

if (config.sentry.isEnabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
    tracesSampleRate: config.sentry.tracesSampleRate,
    sampleRate: config.sentry.sampleRate,
    debug: config.sentry.debug,
    release: config.sentry.release,
    allowUrls: config.sentry.allowUrls,
    environment: config.environment,

    beforeSend(event, hint) {
      if (!hint || !hint.originalException) {
        return event
      }

      const error = hint.originalException

      if (
        !error ||
        !error.message ||
        typeof error.message.match !== "function"
      ) {
        return event
      }

      // blacklisting errors by keyword
      const blacklistKeywords = [
        "al_onAdViewRendered",
        "al_onPoststitialDismiss",
        "ep1.adtrafficquality.google",
        "kaspersky-labs.com",
        "yandex",
        // ignore network errors
        "Failed to fetch",
        // Chinese Android cloud
        "dbankcloud",
      ]
      const blacklistRegex = new RegExp(blacklistKeywords.join("|"), "gi")

      if (error.message.match(blacklistRegex)) {
        return null
      }

      return event
    },

    integrations: function (integrations) {
      // integrations will be all default integrations
      return integrations.map(function (integration) {
        if (integration.name === "GlobalHandlers") {
          // ignore recaptcha errors, see:
          // https://github.com/getsentry/sentry-javascript/issues/3440
          // https://github.com/getsentry/sentry-javascript/issues/2514
          // https://github.com/getsentry/sentry-javascript/issues/5833
          return globalHandlersIntegration({
            onunhandledrejection: false,
          })
        }
        return integration
      })
    },
  })
}
