import {
  EntitlementFeature,
  EntitlementInterface,
} from "@hornet-web-react/core/types/entitlements"
import {
  createEntitlement,
  filterEntitlementsByExpiryDate,
} from "./entitlement-model"
import MemberModel, { MemberApiPayload } from "./member.model"
import { ApiEntitlement, ApiFilter } from "@hornet-web-react/core/types/api"
import {
  defaultServerSessionAnalytics,
  hasPremium,
  hasPremiumPlan,
  ServerSessionAnalytics,
  SessionAccountApiPayload,
  SessionTotalsApiPayload,
  User,
} from "@hornet-web-react/core/types/session"
import { equals } from "ramda"
import { format } from "date-fns"

// TODO: Session: Refactor: rewrite this with zod like `session.profile` is and then parse it for the model
export type ApiSessionPayload = {
  session: {
    access_token: string
    external_access_token?: string | null
    valid_until: Date
    account: SessionAccountApiPayload
    profile: MemberApiPayload["member"]
    settings: {
      advertising?: {
        enabled: [] // TODO: Session: fix
      }
      analytics?: {
        comment_reply?: string
        comment_reply_enabled?: boolean
        honey_bless?: string
        honey_bless_a?: boolean
        notif_rollup?: string
        notif_rollup_rollup?: boolean
        country?: string
      }
      features: string[]
      invites?: {
        enabled?: boolean
        views?: string[]
        grid_impressions?: string[]
      }
      restrictables?: {
        link_account_nag_count?: null
        message_without_photo?: boolean
        message_restriction?: number
      }
      lookup_data_version?: string
      notifications_channel?: string
      delayed_delete_hours?: null
      default_inbox: string
    }
    totals: SessionTotalsApiPayload
    filters: { filter: ApiFilter }[]
    onboarding_objective_set?: {
      upload_profile_photo: null
      set_display_name: null
      follow_member: null
      post_moment: null
      updated_at: null
    }
    public_share_moment_toggle?: {
      enabled: boolean
      text: string
      default_state: boolean
    }
    honey_account: {
      balance: number
    }
    hornet_points_account: {
      id: number
      balance: number
    }
    hornet_x_profile:
      | {
          id: number | null
        }
      | undefined
    entitlements: ApiEntitlement[]
    user_video_audience_options?: null
    on_open_deeplinks: string[]
  }
}

export interface FullSessionInterface {
  hasAwardsEnabled: boolean
  hasAudioMessagesEnabled: boolean
  hasReadReceiptsEnabled: boolean
  hasDeletedMessageExperimentEnabled: boolean
  entitlements: EntitlementInterface[]
  totalHoney: number
  totalAwards: number
  profile: MemberModel
  account: SessionAccountApiPayload
  currentUser: User | undefined
  isSameUser: (user: User) => boolean
  isImperialUnitOfMeasure: boolean
  isAccountEmailVerified: boolean
  hasAccountPhoneNumber: boolean
  isVisible: boolean
  premiumExpirationDateFormatted: string
  hasAutoRenewSubscription: boolean
  hasAutoRenewSubscriptionCancelled: boolean
  isWebPremium: boolean
  isApplePremium: boolean
  isGooglePremium: boolean
  hasPremium: boolean
  onOpenDeeplinks: string[]
  hasHornetXProfile: boolean
  hasLegacyPremium: boolean
  hasPremiumPlan: boolean
  hasInvisibleModeEntitlement: boolean
  hasSwcyoEntitlement: boolean
  hasUnlockedExploreEntitlement: boolean
  hasUnlockedGridEntitlement: boolean
  hasFilterAgeEntitlement: boolean
  hasFilterIdentityEntitlement: boolean
  hasFilterEthnicityEntitlement: boolean
  hasFilterLookingForsEntitlement: boolean
  hasFilterRelationshipEntitlement: boolean
  hasFilterOnlyOnlineEntitlement: boolean
  hasFilterOnlyPositiveKysEntitlement: boolean
  hasFilterOnlyRecentKysEntitlement: boolean
  hasFilterWeightEntitlement: boolean
  hasFilterHeightEntitlement: boolean
}

export function createFullSession(data: ApiSessionPayload) {
  return new FullSessionModel(data)
}

class FullSessionModel implements FullSessionInterface {
  private readonly session: ApiSessionPayload["session"]
  readonly profile: MemberModel
  readonly entitlements: EntitlementInterface[]
  readonly hasInvisibleModeEntitlement: boolean
  readonly hasSwcyoEntitlement: boolean
  readonly hasUnlockedExploreEntitlement: boolean
  readonly hasUnlockedGridEntitlement: boolean
  readonly hasFilterAgeEntitlement: boolean
  readonly hasFilterIdentityEntitlement: boolean
  readonly hasFilterEthnicityEntitlement: boolean
  readonly hasFilterLookingForsEntitlement: boolean
  readonly hasFilterRelationshipEntitlement: boolean
  readonly hasFilterOnlyOnlineEntitlement: boolean
  readonly hasFilterOnlyPositiveKysEntitlement: boolean
  readonly hasFilterOnlyRecentKysEntitlement: boolean
  readonly hasFilterWeightEntitlement: boolean
  readonly hasFilterHeightEntitlement: boolean

  constructor(props: ApiSessionPayload) {
    this.session = props.session
    this.profile = new MemberModel(
      MemberApiPayload.parse({ member: props.session.profile }),
      Number(props.session.profile.unit_of_measure.id) === 1
    )

    this.entitlements = this.session.entitlements
      .filter(filterEntitlementsByExpiryDate)
      .map(createEntitlement)

    this.hasInvisibleModeEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entitlement) =>
          entitlement.feature === EntitlementFeature.InvisibleMode
      )
    this.hasSwcyoEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entitlement) => entitlement.feature === EntitlementFeature.SWCYO
      )

    this.hasUnlockedExploreEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entitlement) =>
          entitlement.feature === EntitlementFeature.UnlockExplore
      )

    this.hasUnlockedGridEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entitlement) => entitlement.feature === EntitlementFeature.UnlockNearby
      )

    this.hasFilterAgeEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.AgeFilter
      )
    this.hasFilterIdentityEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.RoleFilter
      )
    this.hasFilterEthnicityEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.EthnicityFilter
      )
    this.hasFilterLookingForsEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.LookingForFilter
      )
    this.hasFilterRelationshipEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.RelationshipStatusFilter
      )
    this.hasFilterOnlyOnlineEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.OnlineOnlyFilter
      )
    this.hasFilterOnlyPositiveKysEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.KysFilter
      )
    this.hasFilterOnlyRecentKysEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.KysFilter
      )
    this.hasFilterWeightEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.WeightFilter
      )
    this.hasFilterHeightEntitlement =
      this.hasLegacyPremium ||
      this.entitlements.some(
        (entl) => entl.feature === EntitlementFeature.HeightFilter
      )
  }

  get totalAwards(): number {
    return this.session.totals.awards
  }

  get totalHoney(): number {
    return this.session.honey_account.balance || 0
  }

  get currentUser(): User | undefined {
    const user = User.safeParse({
      isAuthenticated: true,
      currentUser: {
        accessToken: String(this.session.access_token),
        accessTokenValidUntil: new Date(this.session.valid_until).toISOString(),
        username: String(this.session.account.username),
        email: String(this.session.account.email),
        isNew: Boolean(this.session.account.new_user),
        hasPremium: hasPremium(this.session.account),
        profileId: String(this.session.profile.id),
        isImperialUnitOfMeasure:
          Number(this.session.profile.unit_of_measure.id) === 1,
        communityToken: null,
      },
    })

    return user.success ? user.data : undefined
  }

  get hasLegacyPremium() {
    return Boolean(this.session.account.premium.active)
  }

  get hasPremiumPlan() {
    return hasPremiumPlan(this.session.account)
  }

  isSameUser(user: User) {
    return equals(user, this.currentUser)
  }

  get isImperialUnitOfMeasure() {
    // TODO: LookupData: maybe some nicer enum for lookups?
    return this.profile.unitOfMeasureId === "1"
  }

  get hasReadReceiptsEnabled() {
    // used to be `this.session.settings.features.includes("read_receipts")`
    return true
  }

  get hasAwardsEnabled() {
    return this.session.settings.features.includes("award_audio_messages")
  }

  get hasAudioMessagesEnabled() {
    return this.session.settings.features.includes("award_audio_messages")
  }

  get isAccountEmailVerified() {
    return this.session.account.email_verified
  }

  get hasDeletedMessageExperimentEnabled() {
    return this.session.settings.features.includes("message_delete")
  }

  get account() {
    return this.session.account
  }

  get hasAccountPhoneNumber() {
    return !!this.session.account.phone_number
  }

  get isVisible() {
    return this.session.profile.visible
  }

  get premiumExpirationDateFormatted() {
    if (this.session.account.premium.valid_until) {
      return format(
        new Date(this.session.account.premium.valid_until),
        "do MMM yyyy"
      )
    }

    if (this.session.account.premium_plan?.expires_at) {
      return format(
        new Date(this.session.account.premium_plan.expires_at),
        "do MMM yyyy"
      )
    }

    return ""
  }

  get hasAutoRenewSubscription() {
    return (
      this.session.account.premium.subscription === true ||
      this.session.account.premium_plan?.renewable === true
    )
  }

  get hasAutoRenewSubscriptionCancelled() {
    return this.session.account.premium.cancelled === true
  }

  get isWebPremium() {
    return (
      this.session.account.premium.app_store_identifier === "web" ||
      this.session.account.premium_plan?.app_store === "web"
    )
  }

  get isApplePremium() {
    return (
      this.session.account.premium.app_store_identifier === "apple" ||
      this.session.account.premium_plan?.app_store === "apple"
    )
  }

  get isGooglePremium() {
    return (
      this.session.account.premium.app_store_identifier === "play" ||
      this.session.account.premium_plan?.app_store === "play"
    )
  }

  get hasPremium() {
    return hasPremium(this.account)
  }

  get onOpenDeeplinks() {
    return this.session.on_open_deeplinks
  }

  get serverSessionAnalytics(): ServerSessionAnalytics {
    try {
      return ServerSessionAnalytics.parse(this.session.settings.analytics)
    } catch (err) {
      return defaultServerSessionAnalytics
    }
  }

  get hasHornetXProfile() {
    return (
      !!this.session.hornet_x_profile &&
      this.session.hornet_x_profile?.id !== null
    )
  }
}
