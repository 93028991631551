import { FC, useEffect } from "react"
import {
  useIpQualityService,
  useIpQualityServiceScriptSrc,
} from "@hornet-web-react/core/hooks/use-ip-quality-service"
import appConfig from "../../app-config"

type BackgroundIpqProfileVerificationProps = {
  //
}

const BackgroundIpqProfileVerification: FC<
  BackgroundIpqProfileVerificationProps
> = () => {
  const ipQualityScriptSrc = useIpQualityServiceScriptSrc(appConfig)
  const {
    ipQualityVerificationResult,
    ipQualityVerificationError,
    verifyIpQualityFingerprint,
    postIpQualityError,
  } = useIpQualityService({
    ipQualityScriptSrc,

    // we don't want to log user out with the background check, and it's not used in this instance
    logoutAction: () => Promise.resolve(),
    getRouteToTrafficFailure: () => "",
    isEnabled: appConfig.ipQualityScore.isEnabled,
  })

  useEffect(() => {
    if (ipQualityVerificationResult !== null) {
      void verifyIpQualityFingerprint(ipQualityVerificationResult, true)
    }
  }, [ipQualityVerificationResult, verifyIpQualityFingerprint])

  useEffect(() => {
    if (ipQualityVerificationError !== null) {
      void postIpQualityError(ipQualityVerificationError, true)
    }
  }, [ipQualityVerificationError, postIpQualityError])

  return null
}

export default BackgroundIpqProfileVerification
