import React, { ReactNode, useContext } from "react"
import {
  useSessionApi,
  useSessionCommunity,
  useSessionDevice,
  useSessionEnvironment,
  useSessionUser,
} from "./session"
import { CreateContainer } from "@hornet-web-react/core/types/services"
import { updateContext } from "@hornet-web-react/core/services/ioc"
import { isClient } from "@hornet-web-react/core/utils"
import { UNKNOWN_DEVICE_ID } from "@hornet-web-react/core/utils/constants"
import Bottle from "bottlejs"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { defaultServerSessionAnalytics } from "@hornet-web-react/core/types/session"

export const ServicesContext = React.createContext<{
  container: Bottle | null
}>({
  container: null,
})

type ServicesProviderProps = {
  container?: Bottle
  createContainer: CreateContainer
  children: ReactNode
  req1?: string
  req2?: string
  appName?: string
  timeZone?: string
}

export const ServicesProvider: React.FC<ServicesProviderProps> = ({
  container,
  createContainer,
  children,
  req1,
  req2,
  appName,
  timeZone,
}) => {
  // provide context for services with the current session data
  const { currentAppUrl, sessionQueryParams, isInApp } = useSessionEnvironment()
  const { locale, deviceId, deviceLocation } = useSessionDevice()
  const { currentUser } = useSessionUser()
  const { token } = useSessionCommunity()

  const {
    community: { setToken, storeToken },
  } = useSessionApi()

  let servicesContainer: Bottle

  const servicesContext = {
    currentAppUrl,
    locale,
    deviceId: deviceId || UNKNOWN_DEVICE_ID,
    profileId: currentUser?.profileId || null,
    accessToken: currentUser?.accessToken || null,
    communityToken: token,
    // on client, we want to save it in cookie
    setCommunityToken: isClient ? storeToken : setToken,
    sessionQueryParams: new URLSearchParams(sessionQueryParams),
    deviceLocation,
    isInApp,
    req1,
    req2,
    appName,
    timeZone,
    serverSessionAnalytics:
      currentUser?.serverSessionAnalytics || defaultServerSessionAnalytics,
  }

  // either use given container and update its context based on session
  if (typeof container !== "undefined") {
    servicesContainer = container

    updateContext(servicesContainer, servicesContext)
  } else {
    // or just make our own
    servicesContainer = createContainer(servicesContext)
  }

  return (
    <ServicesContext.Provider value={{ container: servicesContainer }}>
      {children}
    </ServicesContext.Provider>
  )
}

export function useCoreService<T>(identifier: CORE_TYPES): T {
  const { container } = useContext(ServicesContext)
  if (!container) {
    throw new Error()
  }
  return container.container[identifier]
}

export function useCoreServicesContainer(): Bottle {
  const { container } = useContext(ServicesContext)
  if (!container) {
    throw new Error()
  }

  return container
}
