import { z } from "zod"
import { HornetProfileId, PremiumPaywall, QuickiesPremiumPaywall } from "."
import { ApiEntitlement } from "./api"

export const OfficialAppUrl = z
  .string()
  .url()
  .brand("OfficialAppUrl")
  .refine(
    (val) => {
      try {
        const url = new URL(val)
        return url.protocol === "http:" || url.protocol === "https:"
      } catch {
        return false
      }
    },
    {
      message: "Invalid URL: must start with http:// or https://",
    }
  )
export type OfficialAppUrl = z.infer<typeof OfficialAppUrl>

export const CurrentAppUrl = z
  .string()
  .url()
  .brand("CurrentAppUrl")
  .refine(
    (val) => {
      try {
        const url = new URL(val)
        return url.protocol === "http:" || url.protocol === "https:"
      } catch {
        return false
      }
    },
    {
      message: "Invalid URL: must start with http:// or https://",
    }
  )
export type CurrentAppUrl = z.infer<typeof CurrentAppUrl>

export const DeviceId = z.union([z.string().uuid(), z.literal("unknown")])
export type DeviceId = z.infer<typeof DeviceId>

export const Locale = z.enum([
  "af",
  "af-ZA",
  "ar",
  "ar-SA",
  "be",
  "be-BY",
  "bg",
  "bg-BG",
  "cs",
  "cs-CZ",
  "da",
  "da-DK",
  "de",
  "de-DE",
  "el",
  "el-GR",
  "en",
  "en-CA",
  "en-GB",
  // there's no "en-US" locale because if we don't find given locale in this list,
  // we make it `en` (which is the US version)
  "es",
  "es-ES",
  "es-MX",
  "fa",
  "fa-IR",
  "fil",
  "fil-PH",
  "fi",
  "fi-FI",
  "fr",
  "fr-CA",
  "fr-FR",
  "he",
  "he-IL",
  "hi",
  "hi-IN",
  "hu",
  "hu-HU",
  "id",
  "id-ID",
  "it",
  "it-IT",
  "ja",
  "ja-JP",
  "ko",
  "ko-KR",
  "ky",
  "ky-KG",
  "lt",
  "lt-LT",
  "ms",
  "ms-MY",
  "nl",
  "nl-NL",
  "no",
  "no-NO",
  "pl",
  "pl-PL",
  "pt",
  "pt-BR",
  "pt-PT",
  "ro",
  "ro-RO",
  "ru",
  "ru-RU",
  "sk",
  "sk-SK",
  "sv",
  "sv-SE",
  "th",
  "th-TH",
  "tr",
  "tr-TR",
  "uk",
  "uk-UA",
  "vi",
  "vi-VN",
  "zh",
  "zh-CN",
  "zh-TW",
])
export type Locale = z.infer<typeof Locale>

// for appending Locale to URLs, it's either empty string,
// or `/${locale}` (e.g. `/en`, `/en-US`, `/fr`, `/fr-FR`)
// so it can be used like this: `${LocaleRoute}/map` which turns either
// to `/map` or `/en/map` or `/fr/map` etc.
export const LocaleRoute = z
  .literal("")
  .or(z.string().regex(/^\/[a-z]{2}(-[A-Z]{2})?$/))
export type LocaleRoute = z.infer<typeof LocaleRoute>

export const UtmParam = z.string().nullable()
export type UtmParam = z.infer<typeof UtmParam>

export const GpsCoords = z.object({
  lat: z.number(),
  lng: z.number(),
})
export type GpsCoords = z.infer<typeof GpsCoords>

export const DeviceLocation = z
  .string()
  .regex(
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
  )
  .or(z.literal(""))
  .brand("DeviceLocation")
export type DeviceLocation = z.infer<typeof DeviceLocation>

export const DeviceLocationLatLng = z
  .object({
    lat: z.number(),
    lng: z.number(),
  })
  .brand("DeviceLocationLatLng")
export type DeviceLocationLatLng = z.infer<typeof DeviceLocationLatLng>

export const USER_COUNTRIES = [
  "af",
  "ax",
  "al",
  "dz",
  "as",
  "ad",
  "ao",
  "ai",
  "aq",
  "ag",
  "ar",
  "am",
  "aw",
  "au",
  "at",
  "az",
  "bs",
  "bh",
  "bd",
  "bb",
  "by",
  "be",
  "bz",
  "bj",
  "bm",
  "bt",
  "bo",
  "ba",
  "bw",
  "bv",
  "br",
  "io",
  "bn",
  "bg",
  "bf",
  "bi",
  "kh",
  "cm",
  "ca",
  "cv",
  "ky",
  "cf",
  "td",
  "cl",
  "cn",
  "cx",
  "cc",
  "co",
  "km",
  "cg",
  "cd",
  "ck",
  "cr",
  "ci",
  "hr",
  "cu",
  "cy",
  "cz",
  "dk",
  "dj",
  "dm",
  "do",
  "ec",
  "eg",
  "sv",
  "gq",
  "er",
  "ee",
  "et",
  "fk",
  "fo",
  "fj",
  "fi",
  "fr",
  "gf",
  "pf",
  "tf",
  "ga",
  "gm",
  "ge",
  "de",
  "gh",
  "gi",
  "gr",
  "gl",
  "gd",
  "gp",
  "gu",
  "gt",
  "gg",
  "gn",
  "gw",
  "gy",
  "ht",
  "hm",
  "va",
  "hn",
  "hk",
  "hu",
  "is",
  "in",
  "id",
  "ir",
  "iq",
  "ie",
  "im",
  "il",
  "it",
  "jm",
  "jp",
  "je",
  "jo",
  "kz",
  "ke",
  "ki",
  "kp",
  "kr",
  "kw",
  "kg",
  "la",
  "lv",
  "lb",
  "ls",
  "lr",
  "ly",
  "li",
  "lt",
  "lu",
  "mo",
  "mk",
  "mg",
  "mw",
  "my",
  "mv",
  "ml",
  "mt",
  "mh",
  "mq",
  "mr",
  "mu",
  "yt",
  "mx",
  "fm",
  "md",
  "mc",
  "mn",
  "me",
  "ms",
  "ma",
  "mz",
  "mm",
  "na",
  "nr",
  "np",
  "nl",
  "nc",
  "nz",
  "ni",
  "ne",
  "ng",
  "nu",
  "nf",
  "mp",
  "no",
  "om",
  "pk",
  "pw",
  "ps",
  "pa",
  "pg",
  "py",
  "pe",
  "ph",
  "pn",
  "pl",
  "pt",
  "pr",
  "qa",
  "re",
  "ro",
  "ru",
  "rw",
  "sh",
  "kn",
  "lc",
  "pm",
  "vc",
  "ws",
  "sm",
  "st",
  "sa",
  "sn",
  "rs",
  "sc",
  "sl",
  "sg",
  "sk",
  "si",
  "sb",
  "so",
  "za",
  "gs",
  "es",
  "lk",
  "sd",
  "sr",
  "sj",
  "sz",
  "se",
  "ch",
  "sy",
  "tw",
  "tj",
  "tz",
  "th",
  "tl",
  "tg",
  "tk",
  "to",
  "tt",
  "tn",
  "tr",
  "tm",
  "tc",
  "tv",
  "ug",
  "ua",
  "ae",
  "gb",
  "us",
  "um",
  "uy",
  "uz",
  "vu",
  "ve",
  "vn",
  "vg",
  "vi",
  "wf",
  "eh",
  "ye",
  "zm",
  "zw",
  "unknown",
] as const

export const Country = z.enum(USER_COUNTRIES).nullable()

export type Country = z.infer<typeof Country>

export const TimeZone = z.string().nullable()
export type TimeZone = z.infer<typeof TimeZone>

export const InAppVersion = z
  .string()
  .regex(/^(0|[1-9]\d*)(\.(0|[1-9]\d*))?(\.(0|[1-9]\d*))?$/)
  .nullable()
export type InAppVersion = z.infer<typeof InAppVersion>

export const IsInApp = z.boolean()
export type IsInApp = z.infer<typeof IsInApp>

export const CanAccessAppStore = z.boolean()
export type CanAccessAppStore = z.infer<typeof CanAccessAppStore>

export const CommunityApiToken = z.object({
  token: z.string(),
  validUntil: z.string(),
})
export type CommunityApiToken = z.infer<typeof CommunityApiToken>

export const Otp = z.string().min(1)
export type Otp = z.infer<typeof Otp>

export const AccessToken = z.string().min(1)
export type AccessToken = z.infer<typeof AccessToken>

// TODO: find where it's used and swap for HornetProfileId
export const ProfileId = z.string().min(1)
export type ProfileId = z.infer<typeof ProfileId>

export const ServerSessionAnalytics = z.object({
  country: z.string().nullable().brand("ServerSessionAnalyticsCountry"),
})
export type ServerSessionAnalytics = z.infer<typeof ServerSessionAnalytics>
export const defaultServerSessionAnalytics = ServerSessionAnalytics.parse({
  country: null,
})

export const User = z.object({
  isAuthenticated: z.boolean(),
  currentUser: z
    .object({
      username: z.string(),
      profileId: HornetProfileId,
      hasPremium: z.boolean(),
      accessToken: AccessToken,
      accessTokenValidUntil: z.string(),
      email: z.string().nullable(),
      isImperialUnitOfMeasure: z.boolean(),
      currentCountryCode: Country,
      hasActiveQuickiesProfile: z.boolean(),
      isNew: z.boolean(),
      entitlements: z.array(ApiEntitlement),
      serverSessionAnalytics: ServerSessionAnalytics,
    })
    .nullable(),
})
export type User = z.infer<typeof User>

export const LoggedInUser = User.extend({
  isAuthenticated: z.literal(true),
  currentUser: User.shape.currentUser.unwrap(),
})
export type LoggedInUser = z.infer<typeof LoggedInUser>

export function createDefaultUser() {
  return {
    isAuthenticated: false,
    currentUser: null,
  }
}

export const Community = z.object({
  token: CommunityApiToken.nullable(),
})
export type Community = z.infer<typeof Community>

export const SessionQueryParams = z.instanceof(URLSearchParams)
export type SessionQueryParams = z.infer<typeof SessionQueryParams>

export const SessionQueryParamsRecord = z.record(z.coerce.string())
export type SessionQueryParamsRecord = z.infer<typeof SessionQueryParamsRecord>

export const SessionState = z.object({
  environment: z.object({
    // constant, rarely used (e.g. for canonical URL)
    officialAppUrl: OfficialAppUrl,

    // dynamic, determined via middleware (for everything else)
    currentAppUrl: CurrentAppUrl,
    isInApp: IsInApp,
    inAppVersion: InAppVersion,
    sessionQueryParams: SessionQueryParamsRecord,
    cspNonce: z.string(),
  }),
  device: z.object({
    deviceId: DeviceId,
    locale: Locale,
    country: Country,
    canAccessAppStore: CanAccessAppStore,
    deviceLocation: DeviceLocation, // string on purpose for easier effects deps
    timeZone: TimeZone,
  }),
  user: User,
  community: Community,
  premiumPaywall: PremiumPaywall.or(QuickiesPremiumPaywall).optional(),
})

export type SessionState = z.infer<typeof SessionState>

export const PartialSessionState = SessionState.deepPartial()
export type PartialSessionState = z.infer<typeof PartialSessionState>

export type SessionStateProps = {
  props: {
    session: SessionState
  }
}

export const ShopCurrency = z.enum([
  "aud",
  "brl",
  "idr",
  "rub",
  "chf",
  "twd",
  "thb",
  "try",
  "uah",
  "aed",
  "gbp",
  "usd",
  "eur",
])

export type ShopCurrency = z.infer<typeof ShopCurrency>

export enum SessionStorage {
  QueryParams = "queryParams",
}

export enum SessionQueryParamName {
  Platform = "plt",
  Paywall = "paywall",
  AudienceTag = "audience_tag",
}

export const SessionSettingsApiPayload = z.object({
  // features: z.array(z.string()),
  analytics: ServerSessionAnalytics.optional(),
})
export type SessionSettingsApiPayload = z.infer<
  typeof SessionSettingsApiPayload
>

// aka `SsoTokenExchangeApiPayload`
export const MinimalSessionApiPayload = z.object({
  session: z.object({
    access_token: z.string(),
    valid_until: z.coerce.date(),

    account: z.object({
      id: z.coerce.string(),
      username: z.string(),
      email: z.string().email().nullable(),
      new_user: z.boolean().optional(),
      premium: z.object({
        active: z.boolean(),
        subscription: z.boolean().optional(),
        valid_until: z.coerce.date().nullable().optional(),
      }),
      premium_plan: z
        .object({
          expires_at: z.coerce.date().nullable(),
          product_id: z.string().nullable(),
          renewable: z.boolean(),
          app_store: z.string().nullable(),
        })
        .nullable()
        .optional(),
    }),

    profile: z.object({
      id: HornetProfileId,
      display_name: z.string().nullable(),
      current_country_code: z.string().nullable(),
    }),
  }),
})
export type MinimalSessionApiPayload = z.infer<typeof MinimalSessionApiPayload>

export const LoginApiPayload = z.object({
  session: MinimalSessionApiPayload.shape.session.merge(
    z.object({
      quickies: z
        .object({
          quickies_profile: z.object({
            active: z.boolean(), // bare minimum required for login/middleware checks
          }),
        })
        .optional(),
      entitlements: z.array(ApiEntitlement).optional(),
      settings: SessionSettingsApiPayload.optional(),
    })
  ),
})
export type LoginApiPayload = z.infer<typeof LoginApiPayload>

const SessionTotalsApiPayload = z.object({
  blocks: z.number(),
  private_photo_access_permissions: z.number(),
  favourites: z.number(),
  fans: z.number(),
  matches: z.number(),
  posts: z.number(),
  awards: z.number(),
  spaces: z.number(),
  timeline_head: z.string(),
  timeline_updated_at: z.coerce.date(),
  notifications_head: z.string(),
  notifications_updated_at: z.coerce.date(),
  unread_messages: z.number(),
  primary_inbox_dot: z.boolean(),
  requests_inbox_dot: z.boolean(),
})
export type SessionTotalsApiPayload = z.infer<typeof SessionTotalsApiPayload>

const SessionAccountApiPayload = z.object({
  id: z.number(),
  username: z.string(),
  sanitized_username: z.string(),
  username_claimed: z.boolean(),
  email: z.string(),
  email_verified: z.boolean(),
  has_password: z.boolean(),
  public: z.boolean(),
  email_opt_out: z.boolean(),
  email_not_deliverable: z.boolean(),
  new_user: z.boolean(),
  // ignore the wallet completely
  // wallet: z.object({
  //   claimed_at: z.coerce.date().nullable()
  // }),
  premium: z.object({
    active: z.boolean(),
    subscription: z.boolean().optional(),
    valid_until: z.coerce.date().nullable().optional(),
    premium_plan: z.string().nullable().optional(),
    app_store_identifier: z.string().nullable().optional(),
    cancelled: z.boolean().optional(),
  }),
  premium_plan: z
    .object({
      expires_at: z.coerce.date().nullable(),
      product_id: z.string().nullable(),
      renewable: z.boolean(),
      app_store: z.string().nullable(),
    })
    .nullable()
    .optional(),
  phone_number: z.string().nullable(),
  delete_cancelled: z.boolean(),
})
export type SessionAccountApiPayload = z.infer<typeof SessionAccountApiPayload>

export function hasPremiumPlan(
  accountPayload:
    | MinimalSessionApiPayload["session"]["account"]
    | SessionAccountApiPayload
): boolean {
  if (accountPayload.premium_plan?.expires_at) {
    // HACK: fix once the `ApiSessionPayload` is fully Zod and thus it will always
    // be a Date object
    const expiresAtDate =
      typeof accountPayload.premium_plan.expires_at === "string"
        ? new Date(accountPayload.premium_plan.expires_at)
        : accountPayload.premium_plan.expires_at

    return new Date() < expiresAtDate
  }

  return false
}

export function hasPremium(
  accountPayload:
    | MinimalSessionApiPayload["session"]["account"]
    | SessionAccountApiPayload
): boolean {
  // either user has Premium (legacy universal plan) or Premium Plan

  // legacy way
  if (accountPayload.premium?.active) {
    return true
  }

  return hasPremiumPlan(accountPayload)
}

/**
 * ======================= DEPRECATED BELOW ================================
 */
export type SessionApi = {
  environment: {
    setCurrentAppUrl: (currentAppUrl: CurrentAppUrl) => void
    setInAppVersion: (inAppVersion: InAppVersion) => void
    setSessionQueryParams: (
      sessionQueryParams: SessionQueryParamsRecord
    ) => void
    setCspNonce: (cspNonce: string) => void
  }
  device: {
    setDeviceId: (deviceId: DeviceId) => void
    storeDeviceId: (deviceId: DeviceId) => void
    setCountry: (country: Country) => void
    storeCountry: (country: Country) => void
    setCanAccessAppStore: (canAccessAppStore: CanAccessAppStore) => void
    setDeviceLocation: (deviceLocation: DeviceLocation) => void
  }
  user: {
    setUser: (user: User) => void
    storeUser: (user: User) => void
    setHasPremium: (hasPremium: boolean) => void
    logout: () => void
  }
  community: {
    setToken: (communityToken: CommunityApiToken) => void
    storeToken: (communityToken: CommunityApiToken) => void
  }
  premium: {
    setPremiumPaywall: (
      premiumPaywall: PremiumPaywall | QuickiesPremiumPaywall | undefined
    ) => void
  }
  route: {
    setIsRouteTransitioning: (isTransitioning: boolean) => void
    pushToRouteHistory: (url: string) => void
  }
}
/**
 * ======================= DEPRECATED END ================================
 */
